// Variables
@import "custom";

body {
   color: #292F40;
   font-family: "Spoqa Han Sans Neo", "Apple Gothic SD", malgungothic, "맑은고딕", sans-serif;
   font-weight: 400;
   @media (max-width: 768px) {
      padding-top: 55px;
   }
}

.desktop {
   @media(max-width: 480px) {
      display: none;
      * {
         display: none;
      }
   }
}

.mobile {
   @media (min-width: 481px) {
      display: none;
      * {
         display: none;
      }
   }
}

.invalid-feedback {
   display: block;
}

#top-bar {
   background-color: white;
   border-bottom: 1px solid #ccc;
   color: #3c3c3c;
   width: 100%;
   z-index: 2000;
   font-size: 13px;

   a {
      color: inherit;

      .social-link {
         font-size: 16px;
      }

      &:hover {
         color: rgba(79, 223, 255, 0.9);
      }
   }

   @media (max-width: 480px) {
      display: none;
   }

   .dropdown-menu {
      z-index: 1080;
   }
}

header {
   background-color: white;
   display: block;
   position: relative;
   width: 100%;
   transition: position .4s ease;
   z-index: 1070;

   @media (max-width: 480px) {
      height: 55px;
      padding-top: 0;
      position: fixed;
      top: 0;
      bottom: 0;
   }

   &.frontpage {
      padding-top: 5px;
      padding-bottom: 5px;
      @media (max-width: 767px) {
         padding-top: 0;
         position: fixed;
         top: 0;
      }
   }

   .navbar-brand {
      background-color: transparent;
      font-size: 1.8em;
      font-weight: 700;
      z-index: 100;
      color: rgb(29, 102, 196);

   }

   .logo {
      width: 200px;
      height: auto;
      z-index: 100;

      @media(max-width: 480px) {
         width: 150px;
         height: auto;
      }
   }

   button.mobile-navbar-toggler {
      display: none;
      @media(max-width: 767px) {
         display: inline-block;
         background-color: rgba(0, 0, 0, 0);
         border: 0 !important;
         border-color: hsla(0, 0%, 100%, 0);
         color: #FFFFFF !important;
         position: fixed !important;
         top: 1.1rem !important;
         right: 1.2rem !important;
         padding-left: 0;
         padding-right: 0;
         z-index: 3000;
         .fa {
            &:before {
               font-size: 150%;
            }
         }
      }
   }

   #main-nav-wrap {

      @media (max-width: 768px) {
         .nav-brand {
            position: absolute;
            left: 10px;
            top: -15px;

            img {
               width: 180px;
               height: auto;
            }
         }
         .mobile-navbar-toggler {
            display: inline-block;
            background-color: rgba(0, 0, 0, 0);
            border: 0 !important;
            border-color: rgba(255, 255, 255, 0);
            color: #020202 !important;
            position: fixed !important;
            top: 1.1rem !important;
            right: 1.2rem !important;
            padding-left: 0;
            padding-right: 0;
            z-index: 3000;
         }
      }
   }

   #main-navigation {
      @media(max-width: 768px) {
         position: absolute;
         width: 100vw;
         background-color: #351D42;
         top: 55px;
         left: 0;
      }

      #mainNav {
         @media(max-width: 480px) {
            background-color: rgb(106, 46, 116);
            margin-left: 0 !important;
            margin-right: 0 !important;
            position: fixed;
            top: 55px;
            left: 0;
            width: 100%;
            .navbar-nav {
               align-items: flex-end;
               padding-bottom: 20px;

               > .nav-item {
                  padding-left: 20px;
                  padding-right: 20px;

                  > .nav-link {
                     text-align: right;
                  }
               }
            }
         }
      }

      .nav-item {
         padding-top: .3em;
         padding-bottom: .3em;
         margin-right: 1em;
         margin-left: 1em;
         @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-right: 0;
         }
      }

      .nav-link {
         font-weight: 700;
         font-size: 120%;
         padding: .5em;
         color: #313440;

         &:hover {
            color: rgba(178, 81, 122, 0.9);
         }

         &:focus {
            outline: none;
         }

         @media(max-width: 768px) {
            color: white;
         }
         @media(min-width: 990px) and (max-width: 1208px) {
            font-size: 100%;
         }
      }

      .fa-user {
         color: white;
      }

      .dropdown-toggle {
         &:after {
            display: none;
         }
      }

      .dropdown {
         &.show {
            @include media-breakpoint-down(sm) {
               background-color: #3d1948;
               width: 100%;
            }
         }

         .dropdown-menu {
            border-radius: 0;
            background-color: rgba(23, 28, 45, 0.9);
            @include media-breakpoint-down(sm) {
               border: none;
               background-color: transparent;
               &.show {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-end;

                  .dropdown-item {
                     flex-basis: 33%;
                     text-align: right;
                  }
               }
            }

            .dropdown-item {
               color: #FFFFFF;
               padding: 8px 10px;
               position: relative;

               &:last-child {
                  border-bottom: 0;
               }

               &:active {
                  background-color: rgba(255, 113, 208, 0.9);
               }

               &:hover {
                  background-color: rgba(255, 113, 208, 0.9);
                  color: white;
               }
            }
         }
      }


   }
}

#page-header {
   background: #ad4aa1;

   @media (max-width: 480px) {
      background-size: cover;
   }

   .header-image {
      background: url('/storage/uploads/2018-10/C0Qoppi02diaPybMWWidAuryeX0aRovTwQ8IUfbv.jpeg') no-repeat 50% 50%;
      background-size: cover;
      height: 200px;
      width: 100%;
      @media (max-width: 480px) {
         height: 100px;
      }
   }

   h1.page-title {
      color: white;
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 0;
      letter-spacing: -0.02em;
      position: relative;
      @media (max-width: 480px) {
         text-align: center;
         font-size: 24px;
         padding-bottom: 0;
      }
   }
}

nav[aria-label="breadcrumb"] {
   float: right;

   &:after {
      display: block;
      content: '';
      width: 100%;
      clear: both;
   }

   @media (max-width: 480px) {
      float: none;
      display: block;
      margin-left: auto;
      margin-right: auto;
   }

   .breadcrumb {
      background-color: transparent !important;
      justify-content: center;
      margin-bottom: 0;

      .breadcrumb-item {
         font-weight: 400;
         font-size: 90%;

         &::before {
            color: rgba(206, 219, 225, 0.98);
         }

         a {
            color: white;

            &:hover {
               text-decoration: none;
               color: rgba(255, 194, 249, 0.98);
            }
         }

         &.active {
            color: rgb(44, 255, 178);
         }
      }
   }
}

#content-wrap {
   display: flex;
   padding-top: 2em;
   position: relative;
   @media (max-width: 480px) {
      flex-wrap: wrap;
   }

   aside {
      flex: none;
      width: 250px;
      order: 2;
      padding-left: 1em;
      padding-right: 1em;

      .sidemenu {
         h3.sidemenu-title {
            background: #ad4aa1;
            font-size: 1.25em;
            font-weight: 700;
            color: white;
            text-shadow: 0 0 4px rgba(0, 0, 0, .4);
            position: relative;
            margin-top: 5px;
            padding-top: .6em;
            padding-bottom: .6em;
         }

         .nav-item {
            &:nth-child(2) {
               margin-top: 3px;
            }

            .nav-link {
               color: rgba(59, 60, 76, 0.98);
               padding: 1em 1rem 1em 1.8rem;
               text-indent: -.5em;
               word-break: keep-all;
               white-space: normal;

               i.fa {
                  color: rgba(102, 70, 122, 0.98);
               }

               &:hover {
                  background-color: rgba(163, 202, 10, 0.7);
                  color: rgba(255, 255, 255, 0.98);

                  i.fa {
                     color: white;
                  }
               }

               &.active {
                  background-color: rgba(163, 202, 10, 0.98);
                  color: rgba(255, 255, 255, 0.98);

                  i.fa {
                     color: white;
                  }
               }
            }
         }
      }

      @media (max-width: 480px) {
         display: none;
      }
   }

   main {
      order: 1;
      flex-basis: calc(100% - 250px);
      @media (max-width: 480px) {
         flex-basis: 100%;
         padding-left: 0;
      }
   }
}

.icon-tree {
   position: relative;

   &:before {
      width: 32px;
      height: 32px;
      display: block;
      float: left;
      margin-right: 10px;
      margin-bottom: 10px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
   }

   &.icon-tree-1 {
      &:before {
         background-image: url('/images/icon/icon-tree-1.png');
      }
   }

   &.icon-tree-2 {
      &:before {
         background-image: url('/images/icon/icon-tree-2.png');
      }
   }

   &.icon-tree-3 {
      &:before {
         background-image: url('/images/icon/icon-tree-3.png');
      }
   }
}

.entry-content {
   padding-top: 1em;
   font-size: 16px;

   .entry-single-title {
      line-height: 1.8em;
      @media (max-width: 480px) {
         padding: 1em 0;
      }
   }

   blockquote {
      padding-left: 1.5em;
      border-left: 4px;
      border-color: rgba(162, 173, 192, 0.6);
      border-left-style: solid;
      font-size: 120%;
      font-weight: 700;

      p {
         text-align: justify;
      }
   }

   h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
   }

   h5 {
      font-size: 1.2em;
   }

   ol {
      li {
         padding-top: 5px;
         padding-bottom: 5px;

         ul {
            padding-left: 1em;
            list-style-type: square;

            li {
               padding-top: 2px;
               padding-bottom: 2px;
            }
         }
      }
   }
}

dl {
   dt {
      color: #3E556A;

      .no {
         display: inline-block;
         width: 32px;
         height: 32px;
         background-color: #D4B774;
         text-align: center;
         line-height: 32px;
         border-radius: 16px;
         font-size: 1.2em;
         color: #FFFFFF;
      }
   }
}

iframe {
   display: block;
   border: 0;
   @extend .mx-auto;
}

.notice-row {
   background-color: #F9F2E5;
   padding-left: 1em;
   padding-right: 1em;
}

.title-link {
   color: rgba(5, 15, 31, 0.81);
   font-size: 1.1em;
   transition: all .3s ease;

   &:hover {
      color: rgba(79, 22, 190, 0.84);
      text-decoration: none;
   }
}

.excerpt {
   a {
      text-decoration: none;
      color: rgb(42, 49, 54);

      &:hover {
         color: rgba(79, 22, 190, 0.84);
         text-decoration: inherit;
      }
   }
}

.list-sub-content {
   font-size: 85%;
   font-weight: 300;
   padding: 5px 0;
   color: rgba(94, 98, 99, 0.98);

   .list-inline-item {
      color: #AC6AAE;
   }
}

.list-thumbnail {
   max-height: 170px;
   overflow-y: hidden;
}

.badge-daworks {
   background-color: rgba(190, 203, 205, 0.98);
   color: white;
}

.badge-violet {
   background-color: rgba(121, 64, 98, 0.9);
   color: white;
}

.excerpt {
   font-size: .9em;
}

.front-card {
   border-radius: 0;
   transition: all 1s ease-in-out;

   .thumbnail {
      height: 160px;
      overflow: hidden;
      @media (max-width: 480px) {
         height: 120px;
      }
   }

   .card-img-top {
      transition: 0.6s;
      transform: scale(1);
   }

   .card-body {
      min-height: 160px;
   }

   .card-title {
      font-weight: 700;
      font-size: 1.2em;
      letter-spacing: -0.03em;
   }

   .card-excerpt {
      font-size: .95em;
   }

   &:hover {
      cursor: pointer;

      .card-title {
         text-decoration: none;
         color: rgba(72, 204, 8, 0.98);
      }

      .card-img-top {
         transform: scale(1.2);
      }
   }
}

.btn {
   &.btn-facebook {
      background-color: #4366B0;
      color: #FFFFFF;

      &:hover {
         background-color: rgba(42, 64, 110, 0.98);
      }
   }

   &.btn-google {
      background-color: rgba(198, 72, 82, 0.98);
      color: #FFFFFF;

      &:hover {
         background-color: rgba(123, 45, 51, 0.98);
      }
   }

   &.btn-kakao {
      background-color: rgba(254, 230, 56, 0.98);
      color: rgba(64, 25, 31, 0.98);

      &:hover {
         background-color: rgba(184, 141, 60, 0.98);
      }
   }

   &.btn-naver {
      background-color: #15C643;
      color: #FFFFFF;

      &:hover {
         background-color: #108B31;
      }
   }
}

i.sb {
   display: inline-block;
   width: 32px;
   height: 32px;

   &.sb-facebook {
      content: url('/images/social-login/social-facebook.png');
   }

   &.sb-google {
      content: url('/images/social-login/social-google.png');
   }

   &.sb-naver {
      content: url('/images/social-login/social-naver.png');
   }

   &.sb-kakao {
      content: url('/images/social-login/social-kakao.png');
   }
}

.social-login-btn-group {
}

footer {
   margin-top: 3em;
   font-size: .8em;

   #footer-menu {
      @media (max-width: 480px) {
         display: flex !important;
         justify-content: center;
         padding-top: 0 !important;
      }
   }

   .nav-link {
      color: rgba(141, 161, 167, 0.98);

      &:hover {
         color: rgba(174, 239, 168, 0.98);
      }
   }
}

// Frontpage
#front-mid-banner {
   margin-top: 3em;
   margin-bottom: 3em;
   @media (max-width: 480px) {
      margin-top: 2em;
      margin-bottom: 2em;
   }

   .item {
      position: relative;
      height: 180px;
      @media (max-width: 480px) {
         height: 134px;
      }

      a {
         &:after {
            display: block;
            content: '';
            clear: both;
         }

         img {
            position: absolute;
            top: 0;
            left: 1em;
            width: calc(100% - 2em);
            height: auto;

            &.bnr-front {
               opacity: 1;
               transition: all .5s ease;
            }

            &.bnr-back {
               opacity: 0;
               transition: all .5s ease;
            }
         }
      }

      &:hover {
         .bnr-front {
            opacity: 0;
         }

         .bnr-back {
            opacity: 1;
         }
      }
   }

}

.entry-content {
   /*
   사명 선언문
   */

   #vision {
      .item {
         min-height: 140px !important;
         @media (max-width: 480px) {
            margin-bottom: 1.3em;
         }
      }
   }

   #disciple {
      .item {
         .no {
            font-size: 2em;
            font-weight: 700;
            color: rgba(66, 135, 195, 0.98);
            max-width: 40px;
         }

         .title {
            font-size: 1.4em;
            font-weight: 700;
            color: rgba(66, 135, 195, 0.98);
            padding-top: 8px;
         }
      }
   }

   #membership {
      .circle {
         background-position: center center;
         background-size: 90%;
         background-repeat: no-repeat;
         text-align: center;
         height: 200px;
         line-height: 200px;
         flex-basis: 25%;
         font-weight: 700;
         font-size: 1.15em;
         @media (max-width: 480px) {
            flex-basis: 50%;
         }

         &.circle-1 {
            background-image: url('/images/circle-1.png');
         }

         &.circle-2 {
            background-image: url('/images/circle-2.png');
         }

         &.circle-3 {
            background-image: url('/images/circle-3.png');
         }

         &.circle-4 {
            background-image: url('/images/circle-4.png');
         }
      }
   }

   .abstract {
      padding-right: 1em;
   }

   .photo {
      flex-basis: 160px;
   }

   @media (max-width: 480px) {
      .abstract {
         order: 2;
         padding-right: 0;
         padding-left: 1em;
         flex-basis: calc(100% - 80px);
      }
      .photo {
         flex-basis: 80px;
         order: 1;
      }
   }

   .nf-block {
      background: rgb(255, 237, 247);
      background: linear-gradient(143deg, rgba(255, 237, 247, 0.25674019607843135) 0%, rgba(226, 126, 255, 0.052258403361344574) 100%);
   }

   .nf-block .lead {
      color: #af4998;
      font-weight: 700;
      font-size: 1.4em;
   }

   .edu-course {
      padding-left: 3em;
   }

   .edu-course .v-line {
      position: absolute;
      top: 0;
      left: 1em;
      content: '';
      border-right: 4px solid #FDB3D0;
      height: 100%;
   }

   .edu-course .course {
      display: inline-block;
      position: relative;
      margin-bottom: 1em;
   }

   .edu-course .course h5 {
      color: #9672af;
   }

   .edu-course .course .no {
      display: inline-block;
      position: absolute;
      width: 32px;
      height: 32px;
      font-size: 1.5em;
      font-weight: 700;
      border: 4px solid #FDB3D0;
      border-radius: 50%;
      color: #6d3a82;
      text-align: center;
      line-height: 24px;
      left: -42px;
      top: -6px;
      background-color: white;
   }

}

.bg-podcast {
   background-image: url(/images/podcast_thumbnail.jpg);
   background-position: top center;
   background-repeat: no-repeat;
   background-size: cover;
   height: 200px;

   a {
      color: #F9EFF3;
      font-size: 1.3em;
      transition: all .4s ease-in-out;

      &:hover {
         color: #FFC401;
      }
   }
}

.sermon-card {
   .sermon-content {
      border: 1px solid #C2BFC2;
      border-left: 0;
   }
}


.front-sermon-card {
   &:hover {
      cursor: pointer;
   }

   &.card-action-link {
      transition: all 150ms ease-in-out;

      &:hover {
         box-shadow: 0 0 32px lavenderblush;
         transform: scale(1.05);
      }
   }

   .card-title {
      &.first-title {
         font-size: 1.4em;
      }

      &.title {
         font-size: 1em;
      }
   }

   .card-text {
      small {
         font-size: .9em;
      }
   }
}

#quick-link {
   background: #a24e81;
   padding: 30px 0;

   .quick-link-wrapper {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 10px;
      @include media-breakpoint-only(md) {
         grid-template-columns: repeat(4, 1fr);
      }
      @include media-breakpoint-down(sm) {
         grid-template-columns: repeat(3, 1fr);
      }
   }

   .item {
      overflow:hidden;
      border-radius: 30px;
      a {
         color: white;
         transition: color, text-shadow, background-color 100ms ease-in-out;
         display: flex;
         flex-direction:column;
         justify-content: center;
         align-items: center;
         height: 120px;
         text-align: center;
         word-break: keep-all;

         .icon {
            display:block;
            width:100%;
            max-width:80px;
         }

         h4 {
            color: inherit;
            font-size: 18px;
            margin: 0;
            @include media-breakpoint-down(sm) {
               font-size: 14px;
            }
         }

         &:hover {
            background-color: #761850;
            transform: scale(1.05);
         }
      }
   }


}

#latest-section {
   h3 {
      font-size: 2rem;
   }
}

#related-organization {
   h3 {
      font-size: 2rem;
   }
}

.latest-sermon-card-img {
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
}

.hover-scale {
   transition: all .25s ease-in-out;

   &:hover {
      transform: scale(1.1);
   }
}

#facebook-feed {
   column-count: 4;
   column-gap: 2em;

   figure {
      display: inline-block;
      margin-top: 1em;
      margin-bottom: 1em;

      a {
         color: rgba(26, 32, 44, 0.9);

         &:hover {
            color: rgba(20, 60, 146, 0.9);
         }
      }
   }

   @media (max-width: 480px) {
      column-count: 1;
   }
   @media (min-width: 481px) and (max-width: 768px) {
      column-count: 2;
   }
}

#upcoming-events {
   background-color: rgba(109, 187, 24, .2);

   h3 {
      color: rgba(109, 187, 24, 0.94);
   }
}

#series-contents {
   .card {
      &:hover {
         cursor: pointer;
      }
   }
}

#offering-form {
   background-color: #31305F;

   label {
      height: 35px;
      position: relative;
      color: #8798AB;
      display: block;
      margin-top: 30px;
      margin-bottom: 20px;
   }

   label > span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-weight: 300;
      line-height: 32px;
      color: #8798AB;
      border-bottom: 1px solid #586A82;
      transition: border-bottom-color 200ms ease-in-out;
      cursor: text;
      pointer-events: none;
   }

   label > span span {
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: 0% 50%;
      transition: transform 200ms ease-in-out;
      cursor: text;
   }

   label .field.is-focused + span span,
   label .field:not(.is-empty) + span span {
      transform: scale(0.68) translateY(-36px);
      cursor: default;
   }

   label .field.is-focused + span {
      border-bottom-color: #34D08C;
   }

   .field {
      background: transparent;
      font-weight: 300;
      border: 0;
      color: white;
      outline: none;
      cursor: text;
      display: block;
      width: 100%;
      line-height: 32px;
      padding-bottom: 3px;
      transition: opacity 200ms ease-in-out;
   }

   .field::-webkit-input-placeholder {
      color: #8898AA;
   }

   .field::-moz-placeholder {
      color: #8898AA;
   }

   /* IE doesn't show placeholders when empty+focused */
   .field:-ms-input-placeholder {
      color: #424770;
   }

   .field.is-empty:not(.is-focused) {
      opacity: 0;
   }

   button {
      float: left;
      display: block;
      background: #34D08C;
      color: white;
      border-radius: 2px;
      border: 0;
      margin-top: 20px;
      font-size: 19px;
      font-weight: 400;
      width: 100%;
      height: 47px;
      line-height: 45px;
      outline: none;
   }

   button:focus {
      background: #24B47E;
   }

   button:active {
      background: #159570;
   }

   .outcome {
      float: left;
      width: 100%;
      padding-top: 8px;
      min-height: 20px;
      text-align: center;
   }

   .success, .error {
      display: none;
      font-size: 15px;
   }

   .success.visible, .error.visible {
      display: inline;
   }

   .error {
      color: #E4584C;
   }

   .success {
      color: #34D08C;
   }

   .success .token {
      font-weight: 500;
      font-size: 15px;
   }
}

h4.cell-name {
   color: #5F4D35;
   font-weight: 700;
}

#staffs {
   .card {
      img.card-img-top {
         width: 100%;
         height: auto;
      }

      h4.card-title {
         font-size: 1rem;
         text-align: center;
      }
   }
}

#history {
   .nav-pills {
      .nav-item {
         font-size: 1.2em;
         font-weight: 700;
         color: #3E4955;

         &.active {
            color: white;
            background-color: #6BB6D6;
         }
      }
   }

   dt {
      color: #698DBC;
   }
}

.play-button {
   color: white;
}

.card-thumbnail {
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   width: 100%;
   height: 180px;
   overflow: hidden;
   transition: all 2s ease;

   &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0);
      z-index: 100;
      transition: all 1s ease;
   }

   &:hover {
      background-size: 200%;
      cursor: pointer;

      &:after {
         display: block;
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         background-color: rgba(0, 0, 0, .5);
         z-index: 100;
      }

      .play-button {
         color: rgba(163, 228, 255, 0.89);
         position: relative;
         z-index: 101;
      }
   }
}

#latest-media {
   background-position: 0 50%;
   background-repeat: no-repeat;
   background-size: cover;
   padding-top: 50px;
   padding-bottom: 20px;

   h3 {
      color: rgba(153, 116, 21, 0.94);
   }

   @media(max-width: 480px) {
      background-position-x: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      background-size: contain;
      .display-4 {
         font-size: 250%;
      }
   }
}

#search-section {
   background-color: transparentize(#3B4755, .3);
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   z-index: 3000;

   #search-close {
      position: absolute;
      width: 30px;
      height: 30px;
      font-size: 24px;
      right: 10px;
      top: 10px;

      button {
         background: none;
         color: #FFFFFF;
      }
   }

   #search-form {
      min-width: 320px;
   }
}

h4.sec-title {
   color: #7B6191;
   position: relative;
   padding-top: 10px;
   @media (max-width: 480px) {
      margin-bottom: 1.2em;
   }

   &:before {
      position: absolute;
      content: '';
      border-top: 1px solid #7B6191;
      top: 0;
      left: 0;
      width: 100%;
      @media (max-width: 480px) {
         display: none;
      }
   }

   &:after {
      @media (max-width: 480px) {
         display: inline-block;
         position: absolute;
         content: "";
         border-bottom: 1px solid #7b6191;
         bottom: -10px;
         left: 0;
         width: 100%;
      }
   }

   &.no-line {
      &:before {
         display: none;
      }
   }
}

#service-time {
   .timetable {
      thead {
         th {
            background-color: #d7ceec63 !important;
            color: #3c3436 !important;
         }
      }

      tbody {
         tr {
            th {
               color: #3c3436 !important;
            }
         }
      }
   }

   h5 {
      color: #373a49;
   }

   dl {
      margin-bottom: 1em;
   }
}

#staff-tab {
   .nav-item {
      .nav-link {
         background-color: #ded8dc;
         border-radius: 0;
         color: #38353a;
         margin-left: 10px;
         margin-right: 10px;

         &:first-child {
            margin-left: 0;
         }

         &.active {
            background-color: #38353a;
            color: white;
         }
      }
   }
}

.card.staff {
   .back {
      top: 0;
      left: 0;
   }

   .name {
      color: #A8FFEB;
      font-weight: 700;
   }

   .dept {
      font-size: .85em;
   }

   .sns-link .icon {
      width: 20px;
      height: 20px;
   }
}

#staff-single {
   .icon {
      width: 32px;
   }

   .sns-item {
      margin-bottom: .5em;
   }

   .sns-icon {
      width: 20px;
      height: 20px;
   }
}

#culture-section {
   .dark-orange {
      color: darkorange;
   }

   .lead-box {
      background-position: 100% 100%;
      background-size: contain;
      background-repeat: no-repeat;
      @media (max-width: 480px) {
         background-size: 150%;
         background-position-x: 10%;
      }
   }
}

.event-loop {
   width: 100%;
}

.event-wrap {
   display: flex;
   justify-content: flex-start;
   border-bottom: 1px solid #E1E1F8;

   &:hover {
      cursor: pointer;

      .subject {
         color: #785a53;
      }
   }

   &:last-child {
      border-bottom: 0;
   }

   .event-content {
      flex-basis: 100%;
      padding: 1em;
      padding-left: 0;
      position: relative;

      .date {
         background-color: #f0f7ed;
         position: relative;
         padding: 10px;
         font-weight: 700;
         width: 90px;
         height: 90px;
         @media (max-width: 480px) {
            width: 40px;
            height: 80px;
         }

         .month, .day {
            color: #797985;
            font-size: 2em;
            font-family: 'Bree Serif', serif;
            @media (max-width: 480px) {
               font-size: 1.2em;
            }
         }

         .month {
            position: absolute;
            top: 0;
            left: 10px;
            @media (max-width: 480px) {
               top: 5px;
            }
         }

         .day {
            position: absolute;
            right: 10px;
            bottom: 0;
            @media (max-width: 480px) {
               left: 10px;
               bottom: 5px;
            }
         }

         .line {
            position: absolute;
            width: 80%;
            height: 2px;
            background-color: #d0d6cd;
            transform: rotate(-45deg);
            top: 46px;
            left: 9px;
            @media (max-width: 480px) {
               transform: rotate(0deg);
               top: 39px;
               left: 0;
               width: 100%;
            }
         }
      }

      .subject {
         position: absolute;
         left: 120px;
         top: 14px;
         font-size: 1.4em;
         font-weight: 700;
         color: #6a6a75;
         @media (max-width: 480px) {
            left: 60px;
            font-size: 1.2em;
         }
      }

      .date-detail {
         position: absolute;
         left: 122px;
         top: 50px;
         font-size: 1.15em;
         font-weight: 500;
         color: #6a6a75;
         display: flex;
         flex-direction: column;
         @media (max-width: 480px) {
            left: 60px;
            font-size: 1em;
         }

         .point {
            font-size: 10px;
            color: #c0c0d6;
            position: relative;
            top: -3px;
         }
      }
   }

   .show-detail {
      background-color: #d0d6cd;
      color: #212126;
      display: block;
      position: absolute;
      right: 20px;
      top: 36px;
      padding: 3px 8px;
      font-size: 13px;
      font-weight: 300;
      transition: all 100ms ease-in;

      &:hover {
         color: #322e2d;
         background-color: #B9B89E;
      }

      @media (max-width: 480px) {
         display: none;
      }
   }
}

#event-poster {
   transition: all 300ms ease-in-out;

   &:hover {
      transform: perspective(600px) rotateY(20deg);
   }
}

#event-detail-table {
   tr {
      th {
         color: #9a8d87;

         .fa {
            width: 20px;
         }
      }
   }
}

#next-gen-wrap {

   .nav {
      .nav-item {
         .nav-link {
            background-color: white;
            color: #9468bb;
            font-weight: 700;
            border: 2px solid #9C2FBB;

            &.active {
               background-color: #9C2FBB;
               color: white;
            }

            &:hover {
               background-color: rgba(159, 47, 190, 0.73);
               color: white;
            }
         }
      }
   }

   section {
      display: flex;

      &:after {
         display: block;
         content: '';
         clear: both;
         width: 100%;
         height: 3em;
      }

      .timetable th {
         background-color: rgba(77, 52, 153, 0.23);
         width: 100px;
      }
   }
}

#dept-detail {
   section {
      &:after {
         content: '';
         display: block;
         clear: both;
         width: 100%;
         height: 2em;
      }
   }
}

.round-label {
   background-color: rgba(167, 136, 190, 0.73);
   color: white;
   padding: .5rem 1rem;
   border-radius: 30px;
}

#education-section {
   #curicurum {
      display: flex;
      flex-direction: column;
      margin-left: -5px;
      margin-right: -5px;

      .item {
         display: flex;
         justify-content: flex-start;
         padding: 20px;
         background-color: #fbf4f9;
         margin-left: 5px;
         margin-right: 5px;
         margin-bottom: 5px;

         &:nth-child(even) {
            background-color: #f8eafc;
         }

         @media (max-width: 480px) {
            flex-direction: column;
         }

         .head {
            flex-basis: 240px;

            .t {
               font-weight: 800;
               font-size: 1.2rem;
            }

            p {
               font-size: 1rem;
               margin-bottom: 0;
            }
         }

         ul {
            flex-wrap: wrap;
            flex-basis: calc(100% - 280px);
            padding-left: 2em;
         }

         ul > li {
            font-size: .8rem;

            &:after {
               content: '';
               display: inline-block;
               margin-right: 1em;
            }

            &:last-child::after {
               margin-right: 0;
            }
         }

         @media (max-width: 480px) {
            .head, ul {
               flex-basis: 100%;
            }
            ul {
               margin-top: 1em;
               padding-left: 0;
            }
         }
      }
   }
}

#popup {
   z-index: 3000;

   .modal-dialog {
      @include media-breakpoint-down(sm) {
         max-width: 100%;
         .modal-content {
            top: 55px;

            .modal-header {
               padding-top: 5px;
               padding-bottom: 5px;
            }
         }
      }
   }
}

#giving {
   .account {
      background-color: #e1d7d3;
      font-size: 1.5em;
      padding: 1em;
      text-align: center;
      margin-bottom: 1em;
      border-radius: 4px;
      @include media-breakpoint-down(sm) {
         font-size: 1em;
         font-weight: 700;
      }
   }

   .sample {
      color: #8c4e98;
      font-weight: 700;
   }
}


#young-church {
   /*.sec-title {
       color: rgba(70,37,93,0.89) !important;
   }
   .edu-wrap {
       padding-top:30px;
   }
   .edu-wrap > div {
       display:flex;
       flex-direction:column;
       align-items:center;
       margin-bottom:6px;
       border: 1px solid #eee;
       padding:30px 1em 1em;
       position:relative;
   }
   .no {
       margin-right: .3em;
       background-color: rgba(178, 174, 216, 0.89);
       color: white;
       font-size:1em;
       display:flex;
       width:28px;
       height:28px;
       justify-content:center;
       align-items:center;
       border-radius:50%;
       margin-bottom : 1em;
       position:absolute;
       top:-15px;
       border:3px solid rgba(130, 126, 166, 0.89);
   }
   .edu-wrap > div > p {
       margin-bottom:0;
   }
   #young-church h5 {
       color: rgba(70,37,93,0.89) !important;
   }

   .time-table thead th {
       background-color: rgba(118,103,142,0.89);
       color:white;
   }
   .time-table tbody th {
       background-color: rgba(118,103,142,0.3);
   }
   .card {
       margin-bottom:15px;
       transition:all 300ms ease-in-out;
   }
   .card:hover {
       transform: perspective(600px) rotateY(-35deg);
   }*/

   .links {
      display: grid;
      grid-template-columns: repeat(4, 80px);
      column-gap: 10px;

      .link-item {
         border-radius: 50%;
         display: block;
         width: 80px;
         height: 80px;
         transition: all 200ms ease-out;
         font-size: 12px;
         line-height: 80px;
         color: white;
         text-decoration: none;
         text-align: center;
      }

      a:hover {
         transform: scale(1.12);
      }
   }

   .bg-red {
      background-color: #c70c0b;
   }

   .bg-blue {
      background-color: #1f88f1;
   }

   .bg-green {
      background-color: #0bc731;
   }

   .bg-pink {
      background-color: #f496ae;
   }
}

h4.dark-orange > img {
   width: 32px;
   height: auto;
   position: relative;
}

.history-items {
   .year {
      display: block;
      font-size: 20px;
      font-weight: 700;
      margin-top: 30px;
      margin-bottom: 10px;
      border-bottom: 2px solid #ccc;

      &:first-child {
         margin-top: 0;
      }
   }

   .item {
      display: grid;
      grid-template-columns: 100px auto;
      padding-left: 100px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 5px;
      margin-bottom: 5px;
   }
}

.edu-programs {
   margin-bottom: 40px;

   .item {
      padding: 20px;
      border-bottom: 1px solid $border-color;
      display: grid;
      grid-template-columns: 1fr 2fr;

      &:nth-child(2n) {
         background-color: #eeeeee;
      }

      @include media-breakpoint-down(sm) {
         grid-template-columns: 1fr;
      }

      .title {
         font-weight: 700;
         font-size: 1.15em;
         color: #d40b5f;
         @include media-breakpoint-down(sm) {
            margin-bottom: 10px;
         }
      }

      .p-group {
         display: flex;
         flex-direction: column;

         .program {
            border-bottom: 1px solid $border-color;
            padding-top: 10px;
            padding-bottom: 10px;

            &:first-child {
               padding-top: 0;
            }

            &:last-child {
               border-bottom: none;
            }

            display: grid;
            grid-template-columns: 1fr 2fr;

            .sub-title {
               font-weight: 700;
            }

            .cont {
               word-break: keep-all;
            }
         }
      }
   }
}

.mission-countries {
   display: grid;
   grid-template-columns: repeat(6, 1fr);
   grid-gap: 10px;
   margin-bottom: 20px;

   @include media-breakpoint-down(sm) {
      grid-template-rows: repeat(3, 1fr);
   }

   .country {
      height: 32px;
      position: relative;

      a {
         display: flex;
         position: absolute;
         width: 100%;
         height: 100%;
         justify-content: center;
         align-items: center;
         transition: background-color, color 200ms ease-in-out;
         background-color: #5ca3ab;
         border: 1px solid #36656a;
         color: white;

         &:hover {
            background-color: #0c5460;
            border-color: #094048;
            color: white;
         }
      }
   }
}

/*
실버대학 스타일
*/
.info_bar {
   display:flex;
   justify-content:space-between;
   .info_item {
      flex-basis:33.3333%;
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:center;
      .fa {
         margin-bottom:10px;
         font-size:32px;
         color: dimgray;
      }
      .title {
         font-weight:700;
         margin-bottom:10px;
      }
      .cont {
      }
   }
}

#next-generation-information {
   .sec-title {
      color: rgba(70,37,93,0.89) !important;
   }
   .edu-wrap li {
      display:flex;
      flex-direction:row;
      align-items:center;
      margin-bottom:6px;
   }
   .no {
      flex-basis:28px;
      margin-right: .3em;
      background-color: rgba(178, 174, 216, 0.89);
      color: white;
      font-size:1em;
      display:flex;
      width:28px;
      height:28px;
      justify-content:center;
      align-items:center;
      border-radius:50%;
   }
   .time-table thead th {
      background-color: rgba(118,103,142,0.89);
      color:white;
   }
   .time-table tbody th {
      background-color: rgba(118,103,142,0.3);
   }
   .card {
      margin-bottom:15px;
      transition:all 300ms ease-in-out;
   }
   .card:hover {
      transform: perspective(600px) rotateY(-35deg);
   }
}
