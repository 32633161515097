@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 700;
	src: local('Spoqa Han Sans Neo Bold'),
	url('/fonts/SpoqaHanSansNeo-Bold.woff2') format('woff2'),
	url('/fonts/SpoqaHanSansNeo-Bold.woff') format('woff'),
	url('/fonts/SpoqaHanSansNeo-Bold.ttf') format('truetype');
}


@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 500;
	src: local('Spoqa Han Sans Neo Medium'),
	url('/fonts/SpoqaHanSansNeo-Medium.woff2') format('woff2'),
	url('/fonts/SpoqaHanSansNeo-Medium.woff') format('woff'),
	url('/fonts/SpoqaHanSansNeo-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 400;
	src: local('Spoqa Han Sans Neo Regular'),
	url('/fonts/SpoqaHanSansNeo-Regular.woff2') format('woff2'),
	url('/fonts/SpoqaHanSansNeo-Regular.woff') format('woff'),
	url('/fonts/SpoqaHanSansNeo-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 300;
	src: local('Spoqa Han Sans Neo Light'),
	url('/fonts/SpoqaHanSansNeo-Light.woff2') format('woff2'),
	url('/fonts/SpoqaHanSansNeo-Light.woff') format('woff'),
	url('/fonts/SpoqaHanSansNeo-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 100;
	src: local('Spoqa Han Sans Neo Thin'),
	url('/fonts/SpoqaHanSansNeo-Thin.woff2') format('woff2'),
	url('/fonts/SpoqaHanSansNeo-Thin.woff') format('woff'),
	url('/fonts/SpoqaHanSansNeo-Thin.ttf') format('truetype');
}

// Body
$body-bg: #fff;

// Typography
$font-family-sans-serif: "Spoqa Han Sans Neo", "Apple Gothic SD", malgungothic, "맑은고딕", sans-serif;
$font-family-base: "Spoqa Han Sans Neo", "Apple Gothic SD", malgungothic, "맑은고딕", sans-serif;
$font-size-base: .9rem;
$line-height-base: 1.6;

$link-color: rgb(41, 46, 56);
$link-hover-color: rgb(47, 80, 126);
$link-hover-decoration:none;

$primary: #223E7E;


$custom-file-text: (
				en: "Browse",
				ko: "파일첨부"
);

$embed-responsive-aspect-ratios: (
								(21 9),
								(16 9),
								(4 3),
								(1 1),
								(9 16)
) !default;

@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Nanum+Myeongjo:wght@400;700&display=swap');
@import url('//cdn.jsdelivr.net/font-iropke-batang/1.2/font-iropke-batang.css');
@import '~bootstrap/scss/bootstrap';
@import '~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
@import "~font-awesome/scss/font-awesome";
@import "~owl.carousel/src/scss/owl.carousel";
@import "~owl.carousel/src/scss/owl.theme.default";
@import "~lightbox2/src/css/lightbox.css";
@import "~plyr/src/sass/plyr.scss";

#fileuploadzone {
	height:600px;
	width:calc(100% - 300px);
	background-color:#eee;
	position:fixed;
	top:100px;
	left:275px;
	padding:1em;
	border:3px solid #ccc;
	overflow-y:auto;
	z-index:1000;
	.lead {
		width:100%;
	}
}
#fileuploadzone2 {
	@extend #fileuploadzone;
	min-height:100px;
	height:initial;
	width:100%;
	position:relative;
	top:0;
	left:0;
	margin-bottom:1em;
	border:3px dashed #ccc;
}
#closeUploadzone {
	position:absolute;
	top: .5em;
	right:.5em;
}
#previews {
	display:flex;
	width:100%;
	margin-bottom:10px;
	justify-content:flex-start;
	flex-wrap:wrap;
}

.file-row {
	display:flex;
	flex-direction:column;
	flex-basis:20%;
	align-items:center;
	margin-right:20px;
	margin-bottom:20px;
}

input, .btn, .custom-file-input {
	@extend .rounded-0;
}


// Single Post
.entry_post_info_bar {
	list-style-type: none;
	padding-left:0;
	padding-top:10px;
	padding-bottom:10px;
	border-top:1px solid #eee;
	border-bottom:1px solid #eee;
	margin-bottom:30px;
	text-align:center;
	li {
		display:inline-block;
		margin-left:1em;
		margin-right:1em;
		&:first-child {
			margin-left:0;
		}
		&:last-child {
			margin-right:0;
		}
	}
}

.hide {
	display:none;
}

.mj {
	font-family: 'Iropke Batang', serif;
}
.nanum-mj {
	font-family: 'Nanum Myeongjo', serif;
}

.btn-brown {
		border-color: #9e6c09;
		background-color: #9e6c09;
		color:white;
	&:hover {
		color:white;
		border-color: #5D3C06;
		background-color: #5D3C06;
	}
}

.card {
	display:block !important;
}
.img-fluid, .img-thumbnail {
	width:100%;
}

.text-menu {
	color:white;
	&.text-active {
		color: #fd60ab !important;
		font-weight:700;
	}
}
